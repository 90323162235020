import React from "react"

import { Container, Row, Col } from 'reactstrap'
import Link from '../components/link'
import Button from '../components/btn'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from '../components/form'
import Slider from '../components/slider'
import Box from '../components/box'
import Hr from '../components/hr'
import { FaHome, FaDesktop, FaMobileAlt, FaChartLine, FaBong, FaChalkboardTeacher } from 'react-icons/fa';
import { GoDashboard } from 'react-icons/go';
import Clients from '../components/clients'
import styled from 'styled-components'

let StyledBackground = styled.div`
  background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
`

let Service = ({title, Icon = FaHome, link}) => (
  <div class="col-md-4">
    <Link to={link}>
      <Box>
        <Icon size={40}/>
        <h4 className="mt-3">{title}</h4>
      </Box>
    </Link>
  </div>
)

export default () => (
  <Layout>
    <SEO title="Empathy | Data agency" description="Empathy help brands make better digital presence with data. Specalize in web / app tracking, tag manager implementation, data visaulization and AB testing." keywords="web analytics, app analytics, Google Analytics, Google Tag Manager"/>
    <Slider tagline="We use data to empathize."/>
    <Container className="pt-4">
      <div className="text-center">
        <h4>We help you to put yourself into customer's perspective</h4>
        <p className="text-muted">With 17+ years of experiences on digital and data analytics, we use data to track your customers' behaviour, analyze your data to give recommendations on improving customers' experience, and we validate your success via interactive dashboards.</p>
      </div>
    </Container>
    <Container className="py-5 service">
      <h2 className="text-center mb-4">Our Services</h2>
      <Row>
        <Service title="Website Tracking" Icon={FaDesktop} link="/website-and-app-tracking"/>
        <Service title="App Tracking" Icon={FaMobileAlt} link="/website-and-app-tracking"/>
        <Service title="Website Audit" Icon={FaChartLine} link="/website-audit-and-analysis"/>
      </Row>
      <Row>
        <Service title="Interactive Dashboard" Icon={GoDashboard} link="/dashboard"/>
        <Service title="Website Optimization" Icon={FaBong} link="/website-optimization"/>
        <Service title="Corporate Training" Icon={FaChalkboardTeacher} link="/corporate-training"/>
      </Row>
    </Container>
    <Clients/>
    <StyledBackground>
      <div className="py-5">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <Box style={{textAlign: 'left'}}>
                <h3 className="text-center">Contact Us</h3>
                <Hr/>
                <Form/>
              </Box>
            </Col>
          </Row>
        </Container>
      </div>
    </StyledBackground>
  </Layout>
)
